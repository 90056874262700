import { lazy } from 'react';
import { Redirect } from 'react-router-dom';
// layouts
import OneTimePluginLayout from 'layouts/oneTimePlugin';
import { PATH_PLUGIN } from './paths';
import GuestGuard from '../guards/GuestGuard';

// ----------------------------------------------------------------------

const OneTimeAccessRoutes = {
  path: PATH_PLUGIN.root,
  // guard: GuestGuard,

  layout: OneTimePluginLayout,
  routes: [
    {
      exact: true,
      path: PATH_PLUGIN.expired,
      component: lazy(() => import('../views/plugin/PluginURLExpired'))
    },
    {
      exact: true,
      path: PATH_PLUGIN.newCultureProfile,
      component: lazy(() => import('../views/plugin/PluginCultureProfile'))
    },
    {
      exact: true,
      path: PATH_PLUGIN.cultureProfiles,
      component: lazy(() => import('../views/plugin/PluginCultureProfiles'))
    },
    {
      exact: true,
      path: PATH_PLUGIN.getCultureProfile(':id'),
      component: lazy(() => import('../views/plugin/PluginCultureProfile'))
    },

    {
      exact: true,
      path: PATH_PLUGIN.newJob,
      component: lazy(() => import('../views/plugin/PluginJobPost'))
    },
    {
      exact: true,
      path: PATH_PLUGIN.jobs,
      component: lazy(() => import('../views/plugin/PluginJobsOverview'))
    },
    {
      exact: true,
      path: PATH_PLUGIN.getJobPost(':id'),
      component: lazy(() => import('../views/plugin/PluginJobPost'))
    },
    {
      exact: true,
      path: PATH_PLUGIN.error,
      component: lazy(() => import('../views/plugin/PluginErrorPage'))
    },
    // ----------------------------------------------------------------------

    // maybe we need to redirect to plugin expired page??
    {
      component: () => <Redirect to="/404/" />
    }
  ]
};

export default OneTimeAccessRoutes;
