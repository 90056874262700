import { useTranslation } from 'react-i18next';
import homeFill from '@iconify/icons-eva/home-fill';
import personFill from '@iconify/icons-eva/person-fill';
import { PATH_DASHBOARD } from '../routes/paths';
import { ICurrentLang } from '../hooks/type';

export const categorySelect = [
  { value: 'ATS', name: 'Applicant Tracking (ATS)' },
  { value: 'HRIS', name: 'Human Resource Information (HRIS)' }
];

export const MAX_COMPETENCY_SELECTED_LENGTH = 10;
export const MIN_COMPETENCY_SELECTED_LENGTH = 5;
export const LIMIT = 10e9;
export const MAX_COMPETENCY_SLIDER_RANGE = 9;
export const MIN_COMPETENCY_SLIDER_RANGE = 1;
export const IMG_PLACEHOLDER = '/static/img/illustrations/logo.svg';

export const DEFAULT_MESSAGE_TEXTS = {
  error: 'Something went wrong, please try again later',
  required: 'Please complete all fields before proceeding',
  created: 'Created successfully',
  updated: 'Updated successfully',
  deleted: 'Deleted successfully'
};

export const YEAR_CHOICES = [
  { value: 1, label: '0-1 year' },
  { value: 2, label: '1-3 years' },
  { value: 3, label: '3-5 years' },
  { value: 4, label: '5+ years' }
];

export const CONTRACT_TYPES = [
  { value: 1, label: 'Full-time' },
  { value: 2, label: 'Part-time' },
  { value: 3, label: 'Internship' },
  { value: 4, label: 'Freelance' }
];

export const LANGUAGES = [
  { value: 1, label: 'English' },
  { value: 2, label: 'Dutch' }
];

export const SOCIAL_MEDIAS = [
  { value: 'linkedin', label: 'Linkedin' },
  { value: 'facebook', label: 'Facebook' },
  { value: 'twitter', label: 'Twitter' },
  { value: 'instagram', label: 'Instagram' },
  { value: 'youtube', label: 'YouTube' },
  { value: 'tiktok', label: 'TikTok' },
  { value: 'pinterest', label: 'Pinterest' },
  { value: 'snapchat', label: 'Snapchat' },
  { value: 'google_plus', label: 'Google+' }
];

export const NETWORKS = [
  { value: 1, label: 'instagram' },
  { value: 2, label: 'linkedin' },
  { value: 3, label: 'twitter' },
  { value: 4, label: 'youtube' },
  { value: 5, label: 'facebook' }
];

export const DEGREE_TYPES = [
  { value: 1, label: 'Certificate' },
  { value: 2, label: 'Bachelor' },
  { value: 3, label: 'Master' },
  { value: 4, label: 'PhD' }
];

export const STATUS_OPTIONS = [
  { value: 'draft', label: 'Draft' },
  { value: 'published', label: 'Published' },
  { value: 'closed', label: 'Closed' }
];

export const SALARY_CHOICES = [
  { value: 1, label: '0-2000' },
  { value: 2, label: '2000-3000' },
  { value: 3, label: '3000-4000' },
  { value: 4, label: '4000-5000' },
  { value: 5, label: '5000+' }
];

export const SALARY_CHOICES_INR = [
  { value: 1, label: '0 - 25k' },
  { value: 2, label: '25k - 50k' },
  { value: 3, label: '50k - 80k' },
  { value: 4, label: '80k - 2 Lakh' },
  { value: 5, label: '2+ Lakh' }
];

export const ROLE_CHOICES = [
  { value: 'admin', label: 'Admin' },
  { value: 'recruiter_plus', label: 'Recruiter plus' },
  { value: 'recruiter', label: 'Recruiter' },
  { value: 'candidate', label: 'Candidate' },
  { value: 'employee', label: 'Employee' },
  { value: 'hiring_manager', label: 'Hiring manager' },
  { value: 'reviewer', label: 'Reviewer' }
];

export const USER_ROLES = {
  ADMIN: 'admin',
  RECRUITER_PLUS: 'recruiter_plus',
  RECRUITER: 'recruiter',
  CANDIDATE: 'candidate',
  EMPLOYEE: 'employee',
  HIRING_MANAGER: 'hiring_manager',
  REVIEWER: 'reviewer'
};

export const GENDER_CHOICES = [
  { value: 1, label: 'Female' },
  { value: 2, label: 'Male' },
  { value: 3, label: 'Non-binary' },
  { value: 4, label: 'Rather not to say' }
];

export const CANDIDATE_AVAILABILITY = [
  { value: 1, label: '0-1 Months' },
  { value: 2, label: '1-3 Months' },
  { value: 3, label: '3-6 Months' },
  { value: 4, label: 'More than 6 months' }
];

export const CANDIDATE_WORK_HOURS = [
  { value: 1, label: '0-10 hours per week' },
  { value: 2, label: '10-20 hours per week' },
  { value: 3, label: '20-30 hours per week' },
  { value: 4, label: '30-40 hours per week' }
];

export const PROFICIENCIES_CHOICES = [
  { value: 1, label: 'Elementary proficiency' },
  { value: 2, label: 'Limited working proficiency' },
  { value: 3, label: 'Professional working proficiency' },
  { value: 4, label: 'Full professional proficiency' },
  { value: 5, label: 'Native or bilingual proficiency' }
];

export const IT_SKILLS_PROFICIENCIES_CHOICES = [
  { value: 1, label: 'Elementary proficiency' },
  { value: 2, label: 'Limited working proficiency' },
  { value: 3, label: 'Professional working proficiency' }
];

export const COMPANY_MODULES = [
  { value: 'it_skill', label: 'IT Skills' },
  { value: 'certification', label: 'Certification' },
  { value: 'education', label: 'Education' },
  { value: 'extra', label: 'Extra' },
  { value: 'language', label: 'Language' },
  { value: 'pitch', label: 'Pitch' },
  { value: 'preference', label: 'Preference' },
  { value: 'social_media', label: 'Social Media ' },
  { value: 'social_media_expertise', label: 'Social Media Expertise' },
  { value: 'work_experience', label: 'Work Experience' }
];

export const STAGE_TYPES = [
  { value: 0, label: 'New' },
  { value: 1, label: 'In review' },
  { value: 2, label: 'Contacted' },
  { value: 3, label: 'Interviewed' },
  { value: 4, label: 'Offered' },
  { value: 5, label: 'Hired' }
];

export const SCORE_TYPES = [
  { value: 'score', label: 'Score' },
  { value: 'culture_score', label: 'Culture Score' },
  { value: 'created_at_asc', label: 'Earliest application first' },
  { value: 'created_at_desc', label: 'Latest application first' }
];

export const APPROVAL_TYPE = [
  { value: 1, label: 'Yes' },
  { value: 2, label: 'No' },
  { value: 3, label: 'Maybe' }
];

export const CURRENCY_CHOICES = [
  { value: 'EUR', label: 'EUR' },
  { value: 'USD', label: 'USD' },
  { value: 'GBP', label: 'GBP' },
  { value: 'INR', label: 'INR' }
];

export function getTranslatedList() {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const { t } = useTranslation();
  return {
    DEFAULT_MESSAGE_TEXTS: {
      error: t('Something went wrong, please try again later'),
      required: t('Please complete all fields before proceeding'),
      selected: t('This candidate has already been selected'),
      created: t('Created successfully'),
      updated: t('Updated successfully'),
      deleted: t('Deleted successfully'),
      applied: t('Applied successfully'),
      filtered: 'Jobs updated'
    },
    PERIOD_TYPES: [
      { value: 'daily', label: t('daily') },
      { value: 'weekly', label: t('weekly') },
      { value: 'monthly', label: t('monthly') },
      { value: 'yearly', label: t('yearly') }
    ],
    PERIOD_TYPES_ANALYTICS: [
      { value: 'weekly', label: t('weekly') },
      { value: 'monthly', label: t('monthly') },
      { value: 'quarterly', label: t('quarterly') }
    ],
    MONTH_TYPES: [
      { value: 'Jan', label: 'January' },
      { value: 'Feb', label: 'February' },
      { value: 'Mar', label: 'March' },
      { value: 'Apr', label: 'April' },
      { value: 'May', label: 'May' },
      { value: 'Jun', label: 'June' },
      { value: 'Jul', label: 'July' },
      { value: 'Aug', label: 'August' },
      { value: 'Sep', label: 'September' },
      { value: 'Oct', label: 'October' },
      { value: 'Nov', label: 'November' },
      { value: 'Dec', label: 'December' }
    ],
    MONTH_NUMBERS: {
      Jan: 1,
      Feb: 2,
      Mar: 3,
      Apr: 4,
      May: 5,
      Jun: 6,
      Jul: 7,
      Aug: 8,
      Sep: 9,
      Oct: 10,
      Nov: 11,
      Dec: 12
    },
    STAGE_TYPES: [
      { value: 0, label: t('New') },
      { value: 1, label: t('In review') },
      { value: 2, label: t('Contacted') },
      { value: 3, label: t('Interviewed') },
      { value: 4, label: t('Offered') },
      { value: 5, label: t('Hired') }
    ],
    YEAR_CHOICES: [
      { value: 1, label: t('0-1 Years') },
      { value: 2, label: t('1-3 Years') },
      { value: 3, label: t('3-5 Years') },
      { value: 4, label: t('5+ Years') }
    ],
    CANDIDATE_AVAILABILITY: [
      { value: 1, label: t('0-1 Months') },
      { value: 2, label: t('1-3 Months') },
      { value: 3, label: t('3-6 Months') },
      { value: 4, label: t('More than 6 months') }
    ],
    CANDIDATE_WORK_HOURS: [
      { value: 1, label: t('0-10 hours per week') },
      { value: 2, label: t('10-20 hours per week') },
      { value: 3, label: t('20-30 hours per week') },
      { value: 4, label: t('30-40 hours per week') }
    ],
    COMPANY_MODULES: [
      { value: 'it_skill', label: t('IT Skills') },
      { value: 'certification', label: t('Certification') },
      { value: 'education', label: t('Education') },
      { value: 'extra', label: t('Extra') },
      { value: 'language', label: t('Language') },
      { value: 'pitch', label: t('Pitch') },
      { value: 'preference', label: t('Preference') },
      { value: 'social_media', label: t('Social Media') },
      { value: 'social_media_expertise', label: t('Social Media Expertise') },
      { value: 'work_experience', label: t('Work Experience') }
    ],
    PROFICIENCIES_CHOICES: [
      { value: 1, label: t('Elementary proficiency') },
      { value: 2, label: t('Limited working proficiency') },
      { value: 3, label: t('Professional working proficiency') },
      { value: 4, label: t('Full professional proficiency') },
      { value: 5, label: t('Native or bilingual proficiency') }
    ],
    IT_SKILLS_PROFICIENCIES_CHOICES: [
      { value: 1, label: t('Elementary proficiency') },
      { value: 2, label: t('Limited working proficiency') },
      { value: 3, label: t('Professional working proficiency') }
    ],
    GENDER_CHOICES: [
      { value: 1, label: t('Female') },
      { value: 2, label: t('Male') },
      { value: 3, label: t('Non-binary') },
      { value: 4, label: t('Rather not to say') }
    ],
    STATUS_OPTIONS: [
      { value: 'draft', label: t('Draft') },
      { value: 'published', label: t('Published') },
      { value: 'closed', label: t('Closed') }
    ],
    CAMPAIGN_STATUS_OPTIONS: [
      { value: 1, label: t('Online') },
      { value: 0, label: t('In progress') },
      { value: 2, label: t('Offline') }
    ],
    ROLE_CHOICES: [
      { value: 'admin', label: t('Admin') },
      { value: 'recruiter', label: t('Recruiter') },
      { value: 'candidate', label: t('Candidate') }
    ],
    DEGREE_TYPES: [
      { value: 1, label: t('Certificate') },
      { value: 2, label: t('Bachelor') },
      { value: 3, label: t('Master') },
      { value: 4, label: t('PhD') }
    ],
    CONTRACT_TYPES: [
      { value: 1, label: t('Full-time') },
      { value: 2, label: t('Part-time') },
      { value: 3, label: t('Internship') },
      { value: 4, label: t('Freelance') }
    ],
    LANGUAGES: [
      { value: 1, label: t('English') },
      { value: 2, label: t('Dutch') }
    ],
    SCORE_TYPES: [
      { value: 'score', label: t('Score') },
      { value: 'culture_score', label: t('Culture Score') },
      { value: 'created_at_asc', label: t('Earliest application first') },
      { value: 'created_at_desc', label: t('Latest application first') }
    ],
    APPROVAL_TYPE: [
      { value: 1, label: t('Yes') },
      { value: 2, label: t('No') },
      { value: 3, label: t('Maybe') }
    ],
    SALARY_CHOICES: [
      { value: 1, label: t('0-2000') },
      { value: 2, label: t('2000-2500') },
      { value: 3, label: t('2500-3000') },
      { value: 4, label: t('3000+') }
    ],
    SALARY_CHOICES_INR: [
      { value: 1, label: '0 - 25k' },
      { value: 2, label: '25k - 50k' },
      { value: 3, label: '50k - 80k' },
      { value: 4, label: '80k - 2 Lakh' },
      { value: 5, label: '2+ Lakh' }
    ],
    STATUSES: [
      { value: 1, label: 'draft' },
      { value: 2, label: 'published' },
      { value: 3, label: 'closed' }
    ],
    CURRENCY_CHOICES: [
      { value: 'EUR', label: 'EUR' },
      { value: 'USD', label: 'USD' },
      { value: 'GBP', label: 'GBP' },
      { value: 'INR', label: 'INR' }
    ]
  };
}

export const LIMIT_ASSESSMENT_ERROR_MESSAGE =
  'The quota for the allowed assessment is reached, please contact your recruiter for more info';

export const statusDefault = [
  {
    name: 'In progress',
    status: 'In progress',
    color: '#1C9DD7',
    value: false,
    field: 'in_progress'
  },
  {
    name: 'Completed',
    status: 'Completed',
    color: '#8AE98A',
    value: false,
    field: 'completed'
  }
];

export const LANGS: ICurrentLang[] = [
  {
    value: 'en',
    label: 'English',
    icon1: '/static/img/icons/ic_flag_en.svg'
  },
  {
    value: 'nl',
    label: 'Dutch',
    icon1: '/static/img/icons/ic_flag_nl.svg'
  }
];

export const MENU_OPTIONS = [
  {
    label: 'Home',
    icon: homeFill,
    linkTo: PATH_DASHBOARD.account.showcase
  },
  {
    label: 'User Settings',
    icon: personFill,
    linkTo: PATH_DASHBOARD.user.root
  }
];
