import { setRequestError } from 'redux/slices/errors';
import { store } from 'redux/store';
import { PATH_PLUGIN } from 'routes/paths';
import axios from '../utils/axios';

export type ID = string | number | null;

const navigateToUrl = (url: string) => {
  window.location.href = url;
};

export const getDataByUrl = async (url: string, params?: any) => {
  const { dispatch } = store;

  // Extract token from the current window location
  const token =
    window.location.search.split('?token=')[1]?.split('&')[0] || null;

  const authenticationHeader = token
    ? {
        Authorization: `Signed ${token}`
      }
    : {};

  try {
    const reqParams = params ? { params } : {};

    const config = {
      params: reqParams,
      headers: authenticationHeader
    };

    const response = await axios.get(url, config);
    dispatch(setRequestError([{ response: { status: 200 } }]));
    return response.data;
  } catch (error: any) {
    dispatch(setRequestError(error));
    if (error.response?.data?.detail === 'Signed URL has expired') {
      navigateToUrl(PATH_PLUGIN.expired);
    }
    throw error.response?.data;
  }
};

export const postDataByUrl = async (url: string, data?: any, params?: any) => {
  const { dispatch } = store;
  const token =
    window.location.search.split('?token=')[1]?.split('&')[0] || null;

  const authenticationHeader = token
    ? {
        Authorization: `Signed ${token}`
      }
    : {};

  try {
    const reqParams = params ? { params } : {};

    const config = {
      params: reqParams,
      headers: authenticationHeader
    };

    const response = await axios.post(url, data, config);

    dispatch(setRequestError([{ response: { status: 200 } }]));
    return response.data;
  } catch (error: any) {
    dispatch(setRequestError(error));
    if (error.response?.data) {
      throw error.response.data;
    } else {
      throw error[0]?.response?.data[0];
    }
  }
};

export const updateDataByUrl = async (url: string, data: any, params?: any) => {
  const { dispatch } = store;

  const token =
    window.location.search.split('?token=')[1]?.split('&')[0] || null;

  const authenticationHeader = token
    ? {
        Authorization: `Signed ${token}`
      }
    : {};

  try {
    const reqParams = params ? { params } : {};

    const config = {
      params: reqParams,
      headers: authenticationHeader
    };

    const response = await axios.patch(url, data, config);
    dispatch(setRequestError([{ response: { status: 200 } }]));
    return response.data;
  } catch (error: any) {
    dispatch(setRequestError(error));
    throw error[0]?.response?.data[0];
  }
};

export const putDataByUrl = async (url: string, data: any, params?: any) => {
  const { dispatch } = store;
  try {
    const reqParams = params ? { params } : {};
    const response = await axios.put(url, data, reqParams);
    dispatch(setRequestError([{ response: { status: 200 } }]));
    return response.data;
  } catch (error: any) {
    dispatch(setRequestError(error));
    throw error[0]?.response?.data[0];
  }
};

export const deleteDataByUrl = async (url: string) => {
  const { dispatch } = store;
  try {
    const response = await axios.delete(url);
    dispatch(setRequestError([{ response: { status: 204 } }]));
    return response.status === 204;
  } catch (error: any) {
    dispatch(setRequestError(error));
    throw error[0]?.response?.data[0];
  }
};
