import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';
import { setAccountLanguage } from '../requests/account';
import useAuth from './useAuth';
import { LANGS } from '../constants/constants';
import { ICurrentLang } from './type';
import { enqueueSnackbar } from '../redux/slices/notifications';

export default function useLocales({
  noauth = 'false'
}: { noauth?: string } = {}) {
  const noAuth = noauth === 'true';

  const { i18n, t } = useTranslation();
  const history = useHistory();
  const auth = useAuth();
  const langStorage = localStorage.getItem('i18nextLng');
  const globalLang = LANGS[1].icon1;
  const currentLang: ICurrentLang =
    LANGS.find((_lang) => _lang.value === langStorage) || LANGS[1];

  const handleChangeLanguage = async (newlang: string) => {
    try {
      if (auth.isAuthenticated || noAuth) {
        await setAccountLanguage(newlang);
        await i18n.changeLanguage(newlang);
        history.go(0);
      }
    } catch (err) {
      enqueueSnackbar({
        message: err,
        options: { variant: 'error' }
      });
    }
  };

  return {
    onChangeLang: handleChangeLanguage,
    t,
    currentLang,
    allLang: LANGS,
    globalLang
  };
}
