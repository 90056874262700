import { createSlice } from '@reduxjs/toolkit';
import { store } from '../store';

const ITEMS_PER_PAGE = 6;
const CAMPAIGNS_PER_PAGE = 4;

const initialState: any = {
  openJobPosts: {
    page: 1,
    limit: ITEMS_PER_PAGE,
    offset: 0,
    period: '',
    status: 'published',
    owner: 'all',
    query: '',
    salaries: '',
    degrees: '',
    locations: '',
    skills: [],
    work_exps: '',
    customer: null
  },
  campaigns: {
    page: 1,
    limit: CAMPAIGNS_PER_PAGE,
    offset: 0,
    period: '',
    status: 1,
    query: ''
  },
  mostViewedJobPosts: {
    page: 1,
    limit: 9,
    offset: 0,
    period: '',
    ordering: 'view_count'
  },
  applicantsOverview: {
    page: 1,
    limit: ITEMS_PER_PAGE,
    offset: 0,
    score: 'score',
    vacancy: undefined,
    period: '',
    query: '',
    name: '',
    q: '',
    status: 'live',
    salaries: '',
    work_exps: '',
    degrees: '',
    location: '',
    min_score: 0,
    min_score_culture: 0,
    skills: '',
    customer: '',
    stage: 0
  },
  candidatesOverview: {
    page: 1,
    limit: ITEMS_PER_PAGE,
    offset: 0,
    stage: 0,
    profile: undefined,
    periodicity: '',
    query: '',
    min_score: 0,
    min_score_culture: 0,
    default_lang: null,
    location: '',
    skills: []
  },
  matchCandidatesOverview: {
    page: 1,
    limit: 6,
    offset: 0,
    query: '',
    min_score: 0,
    min_score_culture: 0,
    filterData: {
      language: 0,
      location: '',
      skills: []
    }
  },

  cultureProfiles: {
    page: 1,
    limit: ITEMS_PER_PAGE,
    offset: 0
  },

  teams: {
    page: 1,
    limit: ITEMS_PER_PAGE,
    offset: 0
  }
};

const slice = createSlice({
  name: 'filters',
  initialState,
  reducers: {
    setOpenJobPostsFilter(state, action) {
      state.openJobPosts = {
        ...state.openJobPosts,
        ...action.payload
      };
    },
    setCampaignsFilter(state, action) {
      state.campaigns = {
        ...state.campaigns,
        ...action.payload
      };
    },
    setCultureProfileFilter(state, action) {
      state.cultureProfiles = {
        ...state.cultureProfiles,
        ...action.payload
      };
    },

    setTeamFilter(state, action) {
      state.teams = {
        ...state.teams,
        ...action.payload
      };
    },
    setMostViewedJobPostsFilter(state, action) {
      state.mostViewedJobPosts = {
        ...state.mostViewedJobPosts,
        ...action.payload
      };
    },
    resetOpenJobPostsFilters(state) {
      state.openJobPosts = initialState.openJobPosts;
    },
    resetCampaignsFilters(state) {
      state.campaigns = initialState.campaigns;
    },
    setApplicantsOverviewFilter(state, action) {
      state.applicantsOverview = {
        ...state.applicantsOverview,
        ...action.payload
      };
    },
    resetApplicantsOverviewFilters(state) {
      state.applicantsOverview = initialState.applicantsOverview;
    },
    setCandidatesOverviewFilter(state, action) {
      state.candidatesOverview = {
        ...state.candidatesOverview,
        ...action.payload
      };
    },
    resetCandidatesOverviewFilters(state) {
      state.candidatesOverview = initialState.candidatesOverview;
    },
    setMatchCandidatesOverviewFilter(state, action) {
      state.matchCandidatesOverview = {
        ...state.matchCandidatesOverview,
        ...action.payload
      };
    },
    resetMatchCandidatesOverviewFilters(state) {
      state.matchCandidatesOverview = initialState.matchCandidatesOverview;
    }
  }
});

export const setJobPostsFilter = (field: string, value: any) => () => {
  const updatedPage = field === 'page' || field === 'offset' ? {} : { page: 1 };
  store.dispatch(
    slice.actions.setOpenJobPostsFilter({
      [field]: value,
      ...updatedPage
    })
  );
};

export const setCampaignsFilter = (field: string, value: any) => () => {
  const updatedPage = field === 'page' || field === 'offset' ? {} : { page: 1 };
  store.dispatch(
    slice.actions.setCampaignsFilter({
      [field]: value,
      ...updatedPage
    })
  );
};

export const resetCampaignsFilters = () => () => {
  store.dispatch(slice.actions.resetCampaignsFilters());
};

export const setCultureProfilesFilter = (field: string, value: any) => () => {
  const updatedPage = field === 'page' || field === 'offset' ? {} : { page: 1 };
  store.dispatch(
    slice.actions.setCultureProfileFilter({
      [field]: value,
      ...updatedPage
    })
  );
};

export const setTeamsFilter = (field: string, value: any) => () => {
  const updatedPage = field === 'page' || field === 'offset' ? {} : { page: 1 };
  store.dispatch(
    slice.actions.setTeamFilter({
      [field]: value,
      ...updatedPage
    })
  );
};

export const setMostViewedPostsFilter = (field: string, value: any) => () => {
  const updatedPage = field === 'page' || field === 'offset' ? {} : { page: 1 };
  store.dispatch(
    slice.actions.setMostViewedJobPostsFilter({
      [field]: value,
      ...updatedPage
    })
  );
};

export const resetJobPostsFilters = () => () => {
  store.dispatch(slice.actions.resetOpenJobPostsFilters());
};

export const setApplicantsFilter = (field: string, value: any) => () => {
  const updatedPage =
    field === 'page' || field === 'offset' || field === 'min_score'
      ? {}
      : { page: 1 };
  store.dispatch(
    slice.actions.setApplicantsOverviewFilter({
      [field]: value,
      ...updatedPage
    })
  );
};

export const resetApplicantsFilters = () => () => {
  store.dispatch(slice.actions.resetApplicantsOverviewFilters());
};

export const setCandidatesFilter = (field: string, value: any) => () => {
  const updatedPage =
    field === 'page' || field === 'offset' || field === 'min_score'
      ? {}
      : { page: 1 };
  store.dispatch(
    slice.actions.setCandidatesOverviewFilter({
      [field]: value,
      ...updatedPage
    })
  );
};

export const resetCandidatesFilters = () => () => {
  store.dispatch(slice.actions.resetCandidatesOverviewFilters());
};

export const setMatchCandidatesFilter = (field: string, value: any) => () => {

  const updatedPage =
    field === 'page' ||
    field === 'offset' ||
    field === 'min_score' ||
    field === 'min_score_culture'
      ? {}
      : { page: 1 };
  store.dispatch(
    slice.actions.setMatchCandidatesOverviewFilter({
      [field]: value,
      ...updatedPage
    })
  );
};

export const resetMatchCandidatesFilters = () => () => {
  store.dispatch(slice.actions.resetMatchCandidatesOverviewFilters());
};

export default slice.reducer;
