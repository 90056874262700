// ----------------------------------------------------------------------
function path(root: string, sublink: string) {
  return `${root}${sublink}`;
}

export const ROOTS_AUTH = '/auth/';
export const HOST_URL = `${window.location.origin}`;

// ----------------------------------------------------------------------

export const PATH_AUTH = {
  root: ROOTS_AUTH,
  login: path(ROOTS_AUTH, 'login/'),
  loginUnprotected: path(ROOTS_AUTH, 'login-unprotected/'),
  register: path(ROOTS_AUTH, 'register/'),
  registerUnprotected: path(ROOTS_AUTH, 'register-unprotected/'),
  resetPassword: path(ROOTS_AUTH, 'reset-password/'),
  verify: path(ROOTS_AUTH, 'verify-new-password/')
};

export const PATH_PAGE = {
  comingSoon: '/coming-soon/',
  maintenance: '/maintenance/',
  payment: '/payment/',
  page404: '/404/',
  page500: '/500/',
  denyPrivacy: '/deny-privacy/',
  acceptPrivacy: '/accept-privacy/'
};

export const ROOTS_DASHBOARD = '/dashboard/';
export const DASHBOARD_ACCOUNT = 'account/';
export const DASHBOARD_COMPANY = 'company/';
export const DASHBOARD_INTEGRATION = 'integration/';
export const DASHBOARD_CULTURE = 'culture/';
export const DASHBOARD_JOB_POSTS = 'jobs/';
export const DASHBOARD_CANDIDATES = 'candidates/';
export const DASHBOARD_CAMPAIGNS = 'campaigns/';
export const CUSTOMERS = 'customers/';
export const MESSENGER = 'messenger/';
export const DASHBOARD_USER = 'user/';
export const CUSTOMER_PROFILE = 'customers/';
export const CULTURE_PROFILE = 'culture-profile/';
export const MATCH_PROFILE = 'match-profile/';
export const INTEGRATIONS = 'integrations/';

export const PATH_DASHBOARD = {
  root: ROOTS_DASHBOARD,
  account: {
    root: `${ROOTS_DASHBOARD}${DASHBOARD_ACCOUNT}`,
    showcase: `${ROOTS_DASHBOARD}${DASHBOARD_ACCOUNT}showcase/`
  },
  culture_profile: {
    cultureProfiles: `${ROOTS_DASHBOARD}${DASHBOARD_CULTURE}${CULTURE_PROFILE}all/`,
    cultureProfile: `${ROOTS_DASHBOARD}${DASHBOARD_CULTURE}${CULTURE_PROFILE}current/:id/`,
    getCultureProfileUrl: (id: string | number) =>
      `${ROOTS_DASHBOARD}${DASHBOARD_CULTURE}${CULTURE_PROFILE}current/${id}/`
  },
  company: {
    root: `${ROOTS_DASHBOARD}${DASHBOARD_COMPANY}`,
    settings: `${ROOTS_DASHBOARD}${DASHBOARD_COMPANY}settings/`,
    managementInsights: `${ROOTS_DASHBOARD}${DASHBOARD_COMPANY}management-insights/`,
    cmsSetting: `${ROOTS_DASHBOARD}${DASHBOARD_COMPANY}settings/cms/`,
    integrations: `${ROOTS_DASHBOARD}${DASHBOARD_INTEGRATION}${INTEGRATIONS}`
  },
  user: {
    root: `${ROOTS_DASHBOARD}${DASHBOARD_USER}`
  },
  candidates: {
    root: `${ROOTS_DASHBOARD}${DASHBOARD_CANDIDATES}`,
    candidateProfile: `${ROOTS_DASHBOARD}${DASHBOARD_CANDIDATES}current/:id/`,
    candidateProfileNotFound: `${ROOTS_DASHBOARD}${DASHBOARD_CANDIDATES}current/404page/`,
    findCandidates: `${ROOTS_DASHBOARD}${DASHBOARD_CANDIDATES}#find-candidates/`,
    matchProfile: `${ROOTS_DASHBOARD}${DASHBOARD_CANDIDATES}${MATCH_PROFILE}current/:id/`,
    matchProfileCandidates: `${ROOTS_DASHBOARD}${DASHBOARD_CANDIDATES}${MATCH_PROFILE}candidates/:id/`,
    talentPoolCandidates: `${ROOTS_DASHBOARD}${DASHBOARD_CANDIDATES}account/all_candidates/`,
    chat: `${ROOTS_DASHBOARD}${DASHBOARD_CANDIDATES}current/:id/?chatroom=:room`,
    teams: `${ROOTS_DASHBOARD}${DASHBOARD_CANDIDATES}#teams/`,
    team: `${ROOTS_DASHBOARD}teams/:id/`,
    getMatchProfileCandidatesUrl: (id: string | number) =>
      `${ROOTS_DASHBOARD}${DASHBOARD_CANDIDATES}${MATCH_PROFILE}candidates/${id}/`,
    getCandidateProfileUrl: (id: string | number) =>
      `${ROOTS_DASHBOARD}${DASHBOARD_CANDIDATES}current/${id}/`,
    getMatchProfileUrl: (id: string | number) =>
      `${ROOTS_DASHBOARD}${DASHBOARD_CANDIDATES}${MATCH_PROFILE}current/${id}/`,
    candidatesOverview: `${ROOTS_DASHBOARD}${DASHBOARD_CANDIDATES}#candidates-overview/`,
    getApplicationID: (id: string | number) =>
      `${ROOTS_DASHBOARD}applications/${id}`,
    getTeamID: (id: string | number) => `${ROOTS_DASHBOARD}teams/${id}`,
    getCandidateProfileMagicLink: (
      candidate_id: string | number,
      token: string
    ) => `${ROOTS_DASHBOARD}${DASHBOARD_CANDIDATES}${candidate_id}/${token}`
  },
  jobPosts: {
    root: `${ROOTS_DASHBOARD}${DASHBOARD_JOB_POSTS}`,
    mostViewed: `${ROOTS_DASHBOARD}${DASHBOARD_JOB_POSTS}most-viewed/`,
    applicantsOverview: `${ROOTS_DASHBOARD}${DASHBOARD_JOB_POSTS}applicants-overview/`,
    jobPostsOverview: `${ROOTS_DASHBOARD}${DASHBOARD_JOB_POSTS}overview/`,
    bestMatches: `${ROOTS_DASHBOARD}${DASHBOARD_JOB_POSTS}overview/:id/`,
    getBestMatchesJobUrl: (id?: string | number | null) =>
      `${PATH_DASHBOARD.jobPosts.jobPostsOverview}${id}/`,
    viewtoDoList: `${ROOTS_DASHBOARD}${DASHBOARD_ACCOUNT}viewtodolist/`,
    currentToDoURL: (id?: any) =>
      `${ROOTS_DASHBOARD}${DASHBOARD_ACCOUNT}current/${id}`,
    jobPost: `${ROOTS_DASHBOARD}${DASHBOARD_JOB_POSTS}:id/`,
    getJobPostUrl: (id?: string | number | null) =>
      `${ROOTS_DASHBOARD}${DASHBOARD_JOB_POSTS}${id}/`,
    campaignsOverview: `${ROOTS_DASHBOARD}${DASHBOARD_JOB_POSTS}/campaign-overview/`
  },
  campaigns: {
    root: `${ROOTS_DASHBOARD}${DASHBOARD_CAMPAIGNS}`,
    startCampaign: `${ROOTS_DASHBOARD}${DASHBOARD_CAMPAIGNS}start/`,
    getCampaignsOverviewForJobPost: (id?: string | number | null) =>
      `${ROOTS_DASHBOARD}${DASHBOARD_CAMPAIGNS}overview/?for_vacancy=${id}`,
    campaignMoreInfo: `${ROOTS_DASHBOARD}${DASHBOARD_CAMPAIGNS}current/:id/`,
    getCampaignMoreInfoUrl: (id?: string | number | null) =>
      `${ROOTS_DASHBOARD}${DASHBOARD_CAMPAIGNS}current/${id}/`
  },
  CUSTOMERS: {
    root: `${ROOTS_DASHBOARD}${CUSTOMERS}`,
    customerProfile: `${ROOTS_DASHBOARD}${CUSTOMER_PROFILE}:id/`,
    getCustomerById: (id: any) => `${ROOTS_DASHBOARD}${CUSTOMER_PROFILE}${id}/`
  },
  messenger: {
    root: `${ROOTS_DASHBOARD}${MESSENGER}`
  }
};

export const PATH_HOME = {
  home: '/',
  jobs: '/jobs/',
  demo: '/demo/',
  assessment: '/start_assessment/',
  job: '/jobs/:id/',
  assessmentResult: '/wait_assessment_result',
  getJobUrl: (slug?: string | null) => `/jobs/${slug}/`,
  dashboardRecruiter: PATH_DASHBOARD.account.showcase,
  dashboardCandidate: PATH_DASHBOARD.candidates.getCandidateProfileUrl('me')
};

export const PATH_PLUGIN = {
  root: '/plugin/',
  expired: '/plugin/url-expired',
  newCultureProfile: '/plugin/culture-profiles/new/',
  cultureProfiles: '/plugin/culture-profiles',
  getCultureProfile: (id: string) => `/plugin/culture-profiles/${id}/`,
  newJob: '/plugin/jobs/new/',
  jobs: '/plugin/jobs/',
  getJobPost: (id: string) => `/plugin/jobs/${id}/`,
  error: '/plugin/error/'
};