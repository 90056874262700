import { ReactNode } from 'react';
import { Redirect, useHistory } from 'react-router';
// material
import { Box } from '@material-ui/core';
//
import { useSelector } from 'react-redux';

import { RootState } from '../../redux/store';
import { PATH_AUTH } from '../../routes/paths';
import OneTimePluginNavbar from './OneTimePluginNavbar';

// ----------------------------------------------------------------------

type OneTimePluginLayoutProps = {
  children: ReactNode;
};

export default function OneTimePluginLayout({
  children
}: OneTimePluginLayoutProps) {
  const { company }: any = useSelector(
    (state: RootState) => state.generalRecruiter
  );

  return (
    <Box sx={{ height: '100%' }}>
      <OneTimePluginNavbar
        logo={
          company.logo ||
          'https://recrout-mts.s3.amazonaws.com/apitest/company/1/favicon_N5CNJAy.gif?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=AKIA3BVOE5VKXZRSQJU4%2F20250311%2Feu-central-1%2Fs3%2Faws4_request&X-Amz-Date=20250311T214455Z&X-Amz-Expires=3600&X-Amz-SignedHeaders=host&X-Amz-Signature=5db9011fa36446a699dbe0b418c0b4c51c32ce71bbc82cfc374db9afdd2a5fc4' ||
          '/static/img/illustrations/logo.svg'
        }
      />
      <Box sx={{ height: '100%' }}>{children}</Box>
    </Box>
  );
}
