import { ReactNode, useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { RootState } from 'redux/store';

import { getTranslatedList } from 'constants/constants';
import useLocales from 'hooks/useLocales';
import LoadingScreen from 'components/LoadingScreen';
import { getCmsStylingData } from 'redux/slices/cms';
import { getOptionsData } from 'redux/slices/fixtures';
import { useLocation } from 'react-router';
import { fetchCompetency } from 'redux/slices/jobSlice';
import useAuth from 'hooks/useAuth';

type MainLayoutProps = {
  children: ReactNode;
  location: (e: boolean) => void;
};

export default function MainLayout({ children, location }: MainLayoutProps) {
  const { pathname } = useLocation();

  const { user, isAdmin, isRecruiterPlus, isRecruiter, isAuthenticated } =
    useAuth();

  useEffect(() => {
    if (pathname) {
      if (pathname.includes('dashboard')) location(false);
      else location(true);
    }
  }, [pathname]);

  const { loading }: any = useSelector((state: RootState) => state);

  const dispatch = useDispatch();

  useEffect(() => {
    if (isAuthenticated) {
      dispatch(getCmsStylingData());
    }
  }, [isAuthenticated]);

  const opt = getTranslatedList();

  // TODO: Need to refactor and delete requests
  const { currentLang } = useLocales();
  useEffect(() => {
    dispatch(getOptionsData(opt));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentLang]);

  useEffect(() => {
    if (isAuthenticated && (isAdmin || isRecruiterPlus || isRecruiter)) {
      dispatch(fetchCompetency());
    }
  }, [isAuthenticated, user]);

  if (loading.cms) return null;
  if (loading.app) return <LoadingScreen />;
  return <>{children}</>;
}
