import { NavLink as RouterLink, useLocation } from 'react-router-dom';
import { alpha, experimentalStyled as styled } from '@material-ui/core/styles';
import { AppBar, Box, Container, Toolbar } from '@material-ui/core';

import useOffSetTop from '../../hooks/useOffSetTop';

import Logo from '../../components/Logo';

import LanguagePopover from '../dashboard/navbar/LanguagePopover';
import useLocales from '../../hooks/useLocales';

const APP_BAR_MOBILE = 64;
const APP_BAR_DESKTOP = 96;

const RootStyle = styled(AppBar)(({ theme }) => ({
  boxShadow: 'none',
  background: 'transparent',
  '& .isDesktopActive': {
    position: 'relative'
  },
  '& .isMobileActive': {
    color: theme.palette.primary.main,
    fontWeight: theme.typography.fontWeightMedium,
    backgroundColor: alpha(
      theme.palette.primary.main,
      theme.palette.action.selectedOpacity
    )
  }
}));

const ToolbarStyle = styled(Toolbar)(({ theme }) => ({
  height: APP_BAR_MOBILE,
  transition: theme.transitions.create(['height', 'background-color'], {
    easing: theme.transitions.easing.easeInOut,
    duration: theme.transitions.duration.shorter
  }),
  [theme.breakpoints.up('md')]: { height: APP_BAR_DESKTOP }
}));

const ToolbarShadowStyle = styled('div')(() => ({
  left: 0,
  right: 0,
  bottom: 0,
  height: 24,
  zIndex: -1,
  margin: 'auto',
  borderRadius: '50%',
  position: 'absolute',
  width: `calc(100% - 48px)`
}));

export default function OneTimePluginNavbar({ logo }: { logo: string }) {
  const { pathname } = useLocation();
  const offset = useOffSetTop(100);

  const { t } = useLocales();

  return (
    <RootStyle color="inherit">
      <ToolbarStyle
        disableGutters
        sx={{
          ...(offset && {
            height: { md: APP_BAR_DESKTOP - 20 }
          })
        }}
      >
        <Container
          maxWidth="lg"
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between'
          }}
        >
          <>
            {/* need to add the default logo as back up */}
            <RouterLink to="/">
              {logo ? <Logo url={logo} height="auto" maxHeight="90px" /> : null}
            </RouterLink>
            <Box sx={{ flexGrow: 1 }} />

            <LanguagePopover sx={{ mr: 3 }} noauth="true" />
          </>
        </Container>
      </ToolbarStyle>

      {offset && <ToolbarShadowStyle />}
    </RootStyle>
  );
}
