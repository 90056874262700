import { Icon } from '@iconify/react';
import { useRef, useState } from 'react';
import homeFill from '@iconify/icons-eva/home-fill';
import menu2Fill from '@iconify/icons-eva/menu-2-fill';
import { NavLink as RouterLink, useLocation } from 'react-router-dom';
import { alpha, experimentalStyled as styled } from '@material-ui/core/styles';
import {
  AppBar,
  Box,
  Container,
  Hidden,
  Link,
  List,
  ListItemIcon,
  ListItemText,
  MenuItem,
  Toolbar
} from '@material-ui/core';
import { PATH_AUTH, PATH_HOME } from '../../routes/paths';
import useOffSetTop from '../../hooks/useOffSetTop';
import { MIconButton } from '../../components/@material-extend';
import Logo from '../../components/Logo';
import MenuPopover from '../../components/MenuPopover';
import useAuth from '../../hooks/useAuth';
import LanguagePopover from '../dashboard/navbar/LanguagePopover';
import useLocales from '../../hooks/useLocales';
import { ButtonPrimary } from 'components/new-designer/button/ButtonPrimary';
import { useSelector } from 'react-redux';
import { RootState } from 'redux/store';

const MENU_LINKS = [
  { title: 'Home', icon: homeFill, href: PATH_HOME.home }
  // { title: 'Jobs', icon: homeFill, href: PATH_HOME.jobs }
];

const APP_BAR_MOBILE = 64;
const APP_BAR_DESKTOP = 96;

const RootStyle = styled(AppBar)(({ theme }) => ({
  boxShadow: 'none',
  background: 'transparent',
  '& .isDesktopActive': {
    position: 'relative'
  },
  '& .isMobileActive': {
    color: theme.palette.primary.main,
    fontWeight: theme.typography.fontWeightMedium,
    backgroundColor: alpha(
      theme.palette.primary.main,
      theme.palette.action.selectedOpacity
    )
  }
}));

const ToolbarStyle = styled(Toolbar)(({ theme }) => ({
  height: APP_BAR_MOBILE,
  transition: theme.transitions.create(['height', 'background-color'], {
    easing: theme.transitions.easing.easeInOut,
    duration: theme.transitions.duration.shorter
  }),
  [theme.breakpoints.up('md')]: { height: APP_BAR_DESKTOP }
}));

const ToolbarShadowStyle = styled('div')(() => ({
  left: 0,
  right: 0,
  bottom: 0,
  height: 24,
  zIndex: -1,
  margin: 'auto',
  borderRadius: '50%',
  position: 'absolute',
  width: `calc(100% - 48px)`
}));

export default function HomeNavbar({
  logo,
  login = false
}: {
  logo: string;
  login?: boolean;
}) {
  const anchorRef = useRef(null);
  const { pathname } = useLocation();
  const offset = useOffSetTop(100);
  const [openMenu, setOpenMenu] = useState(false);
  const isWaitAssessmentResultPath = pathname === '/wait_assessment_result';
  const isHome = pathname === '/';
  const auth = useAuth();
  const { t } = useLocales();
  const { base }: any = useSelector((state: RootState) => state.cms);
  const { primary_color } = base;
  const waitingResUnAuthenticated =
    isWaitAssessmentResultPath && !auth.isAuthenticated;

  const renderMenuDesktop = (
    <>
      {MENU_LINKS.map((link) => (
        <Link
          exact
          to={link.href}
          key={link.title}
          underline="none"
          variant="subtitle2"
          component={RouterLink}
          activeClassName="isDesktopActive"
          sx={{
            fontFamily: 'Rubik',
            fontWeight: 400,
            fontSize: '18px',
            textTransform: 'uppercase',

            color: primary_color,
            display: 'relative',
            mr: 5,
            transition: (theme) =>
              theme.transitions.create('opacity', {
                duration: theme.transitions.duration.shortest
              }),
            '&:hover': {
              opacity: 0.48,
              color: (theme) => theme.palette.primary.main
            }
          }}
        >
          {t(link.title)}
          {/* {pathname === link.href && <ActiveLink />} */}
        </Link>
      ))}
    </>
  );

  const renderMenuMobile = (
    <MenuPopover
      disablePortal
      open={openMenu}
      anchorEl={anchorRef.current}
      onClose={() => setOpenMenu(false)}
    >
      <List>
        {MENU_LINKS.map((link) => (
          <MenuItem
            exact
            to={link.href}
            key={link.title}
            component={RouterLink}
            onClick={() => setOpenMenu(false)}
            activeClassName="isMobileActive"
            sx={{ color: 'text.secondary', py: 1 }}
          >
            <ListItemIcon>
              <Icon icon={link.icon} width={20} height={20} />
            </ListItemIcon>
            <ListItemText primaryTypographyProps={{ typography: 'body2' }}>
              {t(link.title)}
            </ListItemText>
          </MenuItem>
        ))}
      </List>
    </MenuPopover>
  );

  const getButtonName = () => {
    let name: string;
    if (auth.isAuthenticated) {
      name = t('Your dashboard');
    }
    //  else if (login) {
    //   name = t('Register');
    // }
    else {
      name = t('Login');
    }
    return name;
  };

  return (
    <RootStyle color="inherit">
      <ToolbarStyle
        disableGutters
        sx={{
          ...(offset && {
            height: { md: APP_BAR_DESKTOP - 20 }
          })
        }}
      >
        <Container
          maxWidth="lg"
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between'
          }}
        >
          <>
            <RouterLink to="/">
              {logo ? <Logo url={logo} height="auto" maxHeight="90px" /> : null}
            </RouterLink>
            <Box sx={{ flexGrow: 1 }} />

            {!waitingResUnAuthenticated && (
              <Hidden mdDown>{renderMenuDesktop}</Hidden>
            )}
            <LanguagePopover sx={{ mr: 3 }} noauth="true" />
            {/* {(auth.isAuthenticated && auth.user.role !== 'candidate') ||
              (auth.isAuthenticated && auth.user.role !== 'employee' && (
                <Box sx={{ mr: 3 }}>
                  <ProductsBasket />
                </Box>
              ))} */}
            {!login && !waitingResUnAuthenticated && (
              <ButtonPrimary
                component={RouterLink}
                to={
                  // eslint-disable-next-line no-nested-ternary
                  !auth.isAuthenticated
                    ? // ? login
                      //   ? PATH_AUTH.register
                      PATH_AUTH.login
                    : auth.user.role === 'candidate' ||
                      auth.user.role === 'employee'
                    ? PATH_HOME.dashboardCandidate
                    : PATH_HOME.dashboardRecruiter
                }
                style={{
                  minWidth: '170px',
                  background: `linear-gradient(92.25deg, ${primary_color}90 -2.57%, ${primary_color} 113.38%)`
                }}
              >
                {getButtonName()}
              </ButtonPrimary>
            )}

            {!waitingResUnAuthenticated && (
              <Hidden mdUp>
                <MIconButton
                  ref={anchorRef}
                  onClick={() => setOpenMenu(true)}
                  sx={{
                    ml: 1,
                    ...(isHome && { color: 'common.white' }),
                    ...(offset && { color: 'text.primary' })
                  }}
                >
                  <Icon icon={menu2Fill} />
                </MIconButton>
                {renderMenuMobile}
              </Hidden>
            )}
          </>
        </Container>
      </ToolbarStyle>

      {offset && <ToolbarShadowStyle />}
    </RootStyle>
  );
}
