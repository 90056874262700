import { createSlice } from '@reduxjs/toolkit';
import { AppDispatch } from 'redux/store';
import {
  getTeams,
  postTeamMember,
  postTeams,
  deleteTeam,
  deleteTeamMember,
  updateTeam,
  getTeam
} from 'requests/teams';
import { ID } from 'requests';
import { enqueueSnackbar } from './notifications';

const ITEMS_PER_PAGE = 12;

const initialState: {
  filters: {
    page: number;
    limit: number;
    offset: number;
    q: string;
    short: boolean;
  };
  response: {
    results: any;
    count: number;
    next: string | null;
    previous: string | null;
  };
  teams: any;
  team: any;
  teamsShort: {
    results: any;
    count: number;
  } | null;
  loading: boolean;
} = {
  filters: {
    page: 1,
    limit: ITEMS_PER_PAGE,
    offset: 0,
    q: '',
    short: false
  },
  response: {
    results: null,
    count: 0,
    next: null,
    previous: null
  },
  teams: {},
  team: {},
  teamsShort: null,
  loading: false
};

const slice = createSlice({
  name: 'teams',
  initialState,
  reducers: {
    setLoading(state, action) {
      state.loading = action.payload;
    },
    setFilter(state, action) {
      state.filters = {
        ...state.filters,
        ...action.payload
      };
    },
    getTeamsSuccess(state, action) {
      state.response = action.payload;
    },
    getTeamSuccess(state, action) {
      state.team = action.payload;
    },
    getTeamsShortSuccess(state, action) {
      state.teamsShort = action.payload;
    },
    postTeamSuccess(state, action) {
      state.response = action.payload;
    },
    deleteTeamSuccess(state, action) {
      state.response = action.payload;
    },
    deleteTeamMemberSuccess(state, action) {
      state.response = action.payload;
    },
    updateTeamSuccess(state, action) {
      state.response = action.payload;
    }
  }
});

export default slice.reducer;

export const getTeamsData =
  (params: {
    limit?: number;
    offset?: number;
    page?: number;
    short?: boolean;
    q?: string;
  }) =>
  async (dispatch: AppDispatch) => {
    dispatch(slice.actions.setLoading(true));
    try {
      const res = await getTeams(params);

      if (params.short) {
        dispatch(slice.actions.getTeamsShortSuccess(res));
      } else {
        dispatch(slice.actions.getTeamsSuccess(res));
      }
    } catch (error) {
      dispatch(
        enqueueSnackbar({
          messageType: 'error',
          options: { variant: 'error' }
        })
      );
    } finally {
      dispatch(slice.actions.setLoading(false));
    }
  };

export const getTeamDataByID =
  (teamId: ID) => async (dispatch: AppDispatch) => {
    dispatch(slice.actions.setLoading(true));
    try {
      const res = await getTeam(teamId);
      dispatch(slice.actions.getTeamSuccess(res));
    } catch (error) {
      dispatch(
        enqueueSnackbar({
          messageType: 'error',
          options: { variant: 'error' }
        })
      );
    } finally {
      dispatch(slice.actions.setLoading(false));
    }
  };

export const createTeam =
  (teamName: string) => async (dispatch: AppDispatch) => {
    if (teamName.length > 0) {
      try {
        const res = await postTeams({ name: teamName });
        dispatch(slice.actions.postTeamSuccess(res));
        dispatch(getTeamsData({ limit: ITEMS_PER_PAGE, offset: 0 }));
      } catch (error) {
        dispatch(
          enqueueSnackbar({
            messageType: 'error',
            options: { variant: 'error' }
          })
        );
      }
    }
  };

export const addTeamMember =
  (teamId: string, candidateId: string) => async (dispatch: AppDispatch) => {
    if (teamId && candidateId) {
      try {
        const res = await postTeamMember(teamId, { candidate: candidateId });
        dispatch(slice.actions.postTeamSuccess(res));
      } catch (error) {
        dispatch(
          enqueueSnackbar({
            messageType: 'error',
            options: { variant: 'error' }
          })
        );
      }
    }
  };

export const deleteTeamData =
  (teamId: number) => async (dispatch: AppDispatch) => {
    if (teamId) {
      try {
        const res = await deleteTeam(teamId);
        dispatch(slice.actions.deleteTeamSuccess(res));
        dispatch(getTeamsData({ limit: ITEMS_PER_PAGE, offset: 0 }));
      } catch (error) {
        dispatch(
          enqueueSnackbar({
            messageType: 'error',
            options: { variant: 'error' }
          })
        );
      }
    }
  };

export const deleteTeamMemberFromTeam =
  (teamId: number, candidateId: number) => async (dispatch: AppDispatch) => {
    if (teamId && candidateId) {
      const data = { candidate: candidateId };
      try {
        const res = await deleteTeamMember(teamId, data);
        dispatch(slice.actions.deleteTeamMemberSuccess(res));
        dispatch(getTeamDataByID(teamId));
        dispatch(getTeamsData({ limit: ITEMS_PER_PAGE, offset: 0 }));
      } catch (error) {
        dispatch(
          enqueueSnackbar({
            messageType: 'error',
            options: { variant: 'error' }
          })
        );
      }
    }
  };

export const updateTeamData =
  (teamId: number, data: any) => async (dispatch: AppDispatch) => {
    if (teamId) {
      try {
        const res = await updateTeam(teamId, data);
        dispatch(slice.actions.updateTeamSuccess(res));
        dispatch(slice.actions.getTeamsSuccess(res));
      } catch (error) {
        dispatch(
          enqueueSnackbar({
            messageType: 'error',
            options: { variant: 'error' }
          })
        );
      }
    }
  };
